import React from 'react';
import {
  FactCheckOutlined as FactCheckIcon,
  PrecisionManufacturingOutlined as PrecisionManufacturingIcon,
  DirectionsRailwayOutlined as DirectionsRailwayIcon,
  WarehouseOutlined as WarehouseIcon,
  LocalShippingOutlined as LocalShippingIcon,
  CelebrationOutlined as CelebrationIcon,
  DoNotDisturbAltOutlined as DoNotDisturbAltOutlinedIcon,
  DirectionsBoatOutlined as DirectionsBoatOutlinedIcon,
  OpenInNewOutlined as OpenInNewOutlinedIcon,
} from '@mui/icons-material';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import advanced from 'dayjs/plugin/advancedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { Box, Typography } from '@mui/material';
import { gql } from '@apollo/client';
import { get, isString } from 'lodash';
import { getStatusChipColor, getInventoryCategoryChipColor } from './statusUtils';
import { Button, Chip } from 'cprime-foundational-components';
import { getNumericInlineValue } from './filterFormatters';
import { getEnv } from './environment';
import theme from '../theme';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advanced);
dayjs.extend(customParseFormat);

export const getJourneyStepColor = ({ isActive, isComplete, sw_status }) => {
  if (sw_status?.toLowerCase() === 'eta unavailable') {
    return theme.palette.common.black;
  }
  if (isComplete) {
    return theme.palette.common.black;
  }
  return isActive ? getStatusChipColor(sw_status?.toLowerCase()) : theme.palette.secondary[30];
};

// Format date

export const formatDate = (timestamp, format) => {
  if (!timestamp) {
    return null;
  }

  return dayjs.utc(timestamp).tz(dayjs.tz.guess()).format(format);
};

export const getFormattedTimeElapsed = (timestamp) => {
  const MINUTE_IN_MILLISECONDS = 60000;
  const HOUR_IN_MILLISECONDS = MINUTE_IN_MILLISECONDS * 60;
  const DAY_IN_MILLISECONDS = HOUR_IN_MILLISECONDS * 24;

  const now = Date.now();
  const timeElapsed = now - timestamp;

  if (timeElapsed < MINUTE_IN_MILLISECONDS) {
    return `${Math.ceil(timeElapsed / MINUTE_IN_MILLISECONDS)} minute ago`;
  } else if (timeElapsed < HOUR_IN_MILLISECONDS) {
    return `${Math.ceil(timeElapsed / MINUTE_IN_MILLISECONDS)} minutes ago`;
  } else if (timeElapsed < DAY_IN_MILLISECONDS) {
    return `${Math.ceil(timeElapsed / HOUR_IN_MILLISECONDS)} hours ago`;
  }
  return formatDate(timestamp, 'MM/DD/YYYY hh:mm A');
};

// Journey event icon generation

export const generateJourneyEventIcon = ({ eventName, color, position, bottom }) => {
  const styles = { color, position, bottom };
  switch (eventName) {
    case 'Prod. Confirm':
      return <FactCheckIcon sx={styles} />;
    case 'Build':
      return <PrecisionManufacturingIcon sx={styles} />;
    case 'Processing':
    case 'VPC Arrival':
    case 'VPC Ready':
      return <WarehouseIcon sx={styles} />;
    case 'Rail Depart':
    case 'Rail Arrive':
    case 'Rail Interchange Arrival':
    case 'Rail Interchange Depart':
      return <DirectionsRailwayIcon sx={styles} />;
    case 'On Truck for Delivery':
    case 'Truck Arrive':
    case 'Truck Depart':
      return <LocalShippingIcon sx={styles} />;
    case 'Arrival at Dealership':
      return <CelebrationIcon sx={styles} />;
    case 'Vessel Depart':
    case 'Vessel Arrive':
      return <DirectionsBoatOutlinedIcon sx={styles} />;
    default:
      return <DoNotDisturbAltOutlinedIcon sx={styles} />;
  }
};

export const getDistrictName = ({ row }) =>
  row.district === 'Unavailable' || !row.district
    ? 'District N/A'
    : row.vehicleAssignmentIndicator
    ? `District ${row.districtFmt}`
    : `District Pool ${row.district}`;

export const getDealerName = ({ row }) => `${row.dealerCode} - ${row.dealerName}`;

// Data Value Formatter Functions

export const generateBoolValue = (params) => (params.value ? 'Y' : 'N');

export const numAddLeadingZeros = (params) => {
  if (!params?.value) {
    return 'N/A';
  }
  params.value = params.value.toString();
  return params.value.padStart(5, '0');
};

export const deepCopy = (data) => {
  return JSON.parse(JSON.stringify(data));
};

export const getGroupFieldName = (filterObj) => {
  const condKey = Object.keys(filterObj)[0];
  if (isGroupField(condKey, filterObj)) {
    return getGroupFieldName(filterObj[condKey][0]);
  } else {
    return condKey;
  }
};

export const isGroupField = (conditionKey, obj) =>
  ['and', 'or'].includes(conditionKey) && Array.isArray(obj[conditionKey]);

export const getGroupName = ({ data, skipGroupPrefix, suffix = '/' }) => {
  let result = skipGroupPrefix ? '' : 'GROUP: ';
  data.forEach((item, index) => {
    if (index === 3) {
      const hiddenItemCount = data.length - 3;
      result = result.concat(` + ${hiddenItemCount}`);
      return;
    }
    if (index > 3) {
      return;
    }
    const condKey = Object.keys(item)[0];
    const groupField = isGroupField(condKey, item);
    let groupNames;
    if (groupField) {
      groupNames = getGroupName({ data: item[condKey], skipGroupPrefix: true, suffix });
    }
    result = result.concat(
      index === 0 ? '' : suffix,
      groupField ? groupNames : getNumericInlineValue(get(item, condKey)),
    );
  });
  return result;
};

export const formatDataValue = ({ value, fallbackString }) => {
  if (value === null || value === undefined || value === '') {
    return isString(fallbackString) ? fallbackString : 'N/A';
  }
  return value;
};

export const convertToLocaleString = (value) =>
  value !== null && value !== undefined ? value.toLocaleString() : value;

export function base64toBlob(base64Data, contentType = 'application/pdf') {
  const byteCharacters = atob(base64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += 512) {
    const slice = byteCharacters.slice(offset, offset + 512);
    const byteNumbers = new Array(slice.length);

    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
}

export function openPdfInNewTab(base64Data) {
  const blob = base64toBlob(base64Data);
  const blobUrl = URL.createObjectURL(blob);

  // Open the PDF in a new tab
  window.open(blobUrl, '_blank');
}

export const renderBase64PdfLink = ({ name, isHeroAttribute, variables, handleClick, loading }) => (
  <Box
    sx={{
      svg: { width: '1rem' },
      marginTop: '-0.188rem',
    }}
    direction="row"
  >
    <Button
      disabled={loading}
      endIcon={
        isHeroAttribute && (
          <OpenInNewOutlinedIcon sx={{ color: 'info.main', fontSize: '1rem' }} fontSize="small" />
        )
      }
      onClick={() => handleClick({ variables })}
      disableRipple
      sx={{
        minWidth: 0,
        minHeight: 0,
        padding: 0,
        color: 'info.main',
        cursor: 'pointer',
        '&:hover': {
          backgroundColor: 'transparent',
          textDecoration: 'underline',
        },
      }}
    >
      <Typography variant={isHeroAttribute ? 'h6' : 'body2'}>{name}</Typography>
    </Button>
  </Box>
);

export const formatShippingPriority = (value) => {
  switch (value) {
    case 'Q':
      return 'High';
    case 'S':
      return 'Standard';
    case 'A':
      return 'Low';
    default:
      return '';
  }
};

export const formatNumber = (value, options) => {
  return new Intl.NumberFormat('en-US', options).format(value);
};

export const withInventoryCategoryChip = ({ value, row }) => {
  if (!row.swEtaStatus) {
    return value;
  }
  const color = getInventoryCategoryChipColor(row.swEtaStatus);
  return (
    <Chip
      backgroundColor={color}
      label={value}
      sx={{
        '& .MuiChip-label': {
          textTransform: 'none',
        },
        '& .MuiTypography-root': { fontWeight: 400 },
      }}
    />
  );
};

export const formatRangeValues = (min, max, operator) => {
  switch (operator) {
    case 'between':
      return `>=${min} & <=${max}`;
    case 'ebetween':
      return `>${min} & <${max}`;
    case 'lbetween':
      return `>=${min} & <${max}`;
    case 'rbetween':
      return `>${min} & <=${max}`;
    default:
      return;
  }
};

export const getNetSalesColumnValue = (netSalesValue) =>
  netSalesValue > 0 ? `+${netSalesValue}` : netSalesValue;

export const formatQuery = (query) => {
  const env = getEnv();

  if (['uat', 'prod'].includes(env)) {
    return gql(query?.loc?.source?.body?.replace('awsRequestId\n', ''));
  }
  return query;
};

export const syncAndReorderMenuData = (source, orderArr) => {
  const sourceMap = new Map(source.map((item) => [item.id, item]));

  let orderedPart = orderArr.filter((id) => sourceMap.has(id)).map((id) => sourceMap.get(id));

  let finalResult = [];
  let orderIndex = 0;

  source.forEach((currentItem) => {
    if (!orderArr.includes(currentItem.id)) {
      finalResult.push(currentItem);
    } else {
      finalResult.push(orderedPart[orderIndex]);
      orderIndex++;
    }
  });

  return finalResult;
};

export const parseDateFormat = ({ dateValue, type }) => {
  if (type && type.toLowerCase() === 'date' && dateValue !== '' && dateValue !== null) {
    const baseFormats = [
      'MM/DD/YYYY',
      'MM/D/YYYY',
      'M/DD/YYYY',
      'M/D/YYYY',
      'D/M/YYYY',
      'D/MM/YYYY',
      'DD/M/YYYY',
      'DD/MM/YYYY',
      'YYYY/MM/DD',
    ];

    const separators = ['.', '-', ' '];
    const results = [];

    baseFormats.forEach((format) => {
      separators.forEach((sep) => {
        const replaced = format.replace(/\//g, sep);
        results.push(replaced);
      });
    });

    for (const format of [...baseFormats, ...results]) {
      const parsedDate = dayjs(dateValue, format, true);
      if (parsedDate.isValid()) {
        return parsedDate.format('MM/DD/YYYY');
      }
    }
  }
  return dateValue;
};
