import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import TooltipWrapper from '../TooltipWrapper/TooltipWrapper';

const EllipsisWithTooltip = ({
  children,
  tooltipText,
  isAlwaysShowTooltip = false,
  isEllipsisWithTooltip = true,
  isTruncatedTooltip = false,
  isPrint = false,
}) => {
  const childRef = useRef(null);
  const [truncatedText, setTruncatedText] = useState('');

  useEffect(() => {
    const checkIsTruncated = () => {
      if (childRef.current) {
        const widthValue = childRef.current.style.width;
        const clientWidth = childRef?.current?.getBoundingClientRect().width;
        childRef.current.style.overflow = 'visible';
        childRef.current.style.width = 'max-content';
        const contentWidth = childRef?.current?.getBoundingClientRect().width;
        childRef.current.style.width = widthValue;
        childRef.current.style.overflow = 'hidden';

        if (clientWidth < contentWidth) {
          const text = childRef.current.innerText;
          setTruncatedText(text);
          if (isTruncatedTooltip) {
            const truncatedTextLength = (contentWidth - clientWidth) / 6.75;
            const remainingText = text.substring(text.length - truncatedTextLength);
            setTruncatedText(remainingText);
          }
        } else {
          setTruncatedText('');
        }
      }
    };
    if (childRef.current?.offsetWidth && childRef.current?.scrollWidth) {
      checkIsTruncated();
    }

    window.addEventListener('resize', checkIsTruncated);
    return () => window.removeEventListener('resize', checkIsTruncated);
  }, [childRef]);

  if (!children || !isEllipsisWithTooltip || isPrint) {
    return children;
  }

  const refChildren = React.cloneElement(children, { ref: childRef });

  return truncatedText.length > 0 || isAlwaysShowTooltip ? (
    <TooltipWrapper
      tooltipText={tooltipText || `...${truncatedText}` || refChildren}
      placement="bottom"
    >
      {refChildren}
    </TooltipWrapper>
  ) : (
    refChildren
  );
};

EllipsisWithTooltip.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  tooltipText: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
  isAlwaysShowTooltip: PropTypes.bool,
  isEllipsisWithTooltip: PropTypes.bool,
  isTruncatedTooltip: PropTypes.bool,
  isPrint: PropTypes.bool,
};

export default EllipsisWithTooltip;
