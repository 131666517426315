import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { find, filter, forOwn, groupBy, isEmpty } from 'lodash';
import { Box, FormControlLabel, FormGroup, IconButton, Stack, Typography } from '@mui/material';
import { ArrowBack as ArrowBackIcon } from '@mui/icons-material';
import SearchBar from 'components/SearchBar/SearchBar';
import { CascadingFiltersList } from '../CascadingFiltersList/CascadingFiltersList';
import { getLabelForChip } from '../../utils';
import * as S from './CascadingFiltersListSection.styles';
import { Button } from 'cprime-foundational-components';

export const CascadingFiltersListSection = ({
  headerName,
  options,
  setCascadingFilters,
  cascadingFilters,
  setListSectionData,
  setCascadingFiltersLocal,
  cascadingFiltersLocal,
  applyFiltersHandler,
  resetAllHandler,
  hasUncheckedSection,
  cascadingFiltersConfig,
  isCascadingFilterOff,
}) => {
  const sectionFiled = options?.[0]?.field;
  const [isFiltered, setIsFiltered] = useState(false);
  const [showOnlySelected, setShowOnlySelected] = useState(false);
  const [filteredOptions, setFilteredOptions] = useState(options);
  const [listFilters, setListFilters] = useState({ searchString: '', showOnlySelected: false });

  useEffect(() => {
    if (isEmpty(options)) {
      return;
    }

    let newOptions = [...options];
    const { searchString, showOnlySelected } = listFilters;

    if (showOnlySelected) {
      newOptions = filter(options, { isChecked: true });
      setShowOnlySelected(true);
    }

    if (searchString === '') {
      setFilteredOptions(newOptions);
    } else {
      const lowercaseSearch = searchString.toLowerCase();
      const filteredOptions = newOptions.filter((option) =>
        option.displayName.toLowerCase().includes(lowercaseSearch),
      );
      setFilteredOptions(filteredOptions);
    }
  }, [listFilters, options]);

  if (isEmpty(options)) {
    return null;
  }

  const listApplyFiltersHandler = () => {
    if (!isEmpty(filteredOptions)) {
      let newCascadingFiltersLocal = [];

      const groupedCascadingFiltersLocal = groupBy(cascadingFiltersLocal, 'field');
      forOwn(groupedCascadingFiltersLocal, (value, field) => {
        if (field !== sectionFiled) {
          newCascadingFiltersLocal = [...newCascadingFiltersLocal, ...value];
        }
      });

      const newListFilters = options.map(({ field, value }) => {
        const foundOption = find(filteredOptions, { field, value });
        return { field, value, isChecked: foundOption?.isChecked || false };
      });
      newCascadingFiltersLocal = [...newCascadingFiltersLocal, ...newListFilters];

      setCascadingFiltersLocal(newCascadingFiltersLocal);
      applyFiltersHandler({ newCascadingFiltersLocal });
    }
  };

  return (
    <Stack>
      <Stack
        p="1rem 1rem 0.5rem 0.5rem"
        direction="row"
        sx={{
          borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
          height: '3rem',
        }}
      >
        <IconButton onClick={() => setListSectionData(null)}>
          <ArrowBackIcon fontSize="small" sx={{ color: (theme) => theme.palette.primary.main }} />
        </IconButton>
        <Typography variant="h5">{headerName}</Typography>
        <S.StyledChip
          label={getLabelForChip({ headerName, options })}
          sx={{
            '&, *': {
              cursor: 'pointer',
            },
          }}
        />
      </Stack>
      <Stack
        p="0.5rem 1rem 1rem"
        sx={{ borderBottom: (theme) => `1px solid ${theme.palette.divider}` }}
      >
        <Box pb="0.625rem">
          <Typography variant="body4">Values Filtered by Brand, Region</Typography>
        </Box>
        <Box pb="1.5rem">
          <FormGroup>
            <FormControlLabel
              variant="body2"
              sx={{ height: '1rem' }}
              control={
                <S.StyledFilterSwitch
                  checked={listFilters.showOnlySelected}
                  onClick={() =>
                    setListFilters({
                      ...listFilters,
                      showOnlySelected: !listFilters.showOnlySelected,
                    })
                  }
                />
              }
              label={<Typography variant="body4">Show Only Selected</Typography>}
            />
          </FormGroup>
        </Box>
        <Box>
          <SearchBar
            value={listFilters.searchString}
            placeholder={`Search ${headerName.toLowerCase()}...`}
            handleSearch={(searchString) => setListFilters({ ...listFilters, searchString })}
            handleChange={(searchString) => {
              if (searchString.length >= 1) {
                setIsFiltered(true);
                setListFilters({ ...listFilters, searchString });
              } else {
                searchString = '';
                setIsFiltered(false);
                setListFilters({ ...listFilters, searchString });
              }
            }}
          />
        </Box>
      </Stack>
      <CascadingFiltersList
        headerName={headerName}
        options={isFiltered || showOnlySelected ? filteredOptions : options}
        setCascadingFilters={setCascadingFilters}
        cascadingFilters={cascadingFilters}
        setCascadingFiltersLocal={setCascadingFiltersLocal}
        cascadingFiltersLocal={cascadingFiltersLocal}
        isFiltered={isFiltered}
        isCascadingFilterOff={isCascadingFilterOff}
        cascadingFiltersConfig={cascadingFiltersConfig}
        fixedHeight
        virtualizedList
      />
      <Stack p="1rem" spacing={1} direction="row">
        <Button
          variant="tertiary"
          onClick={() => {
            resetAllHandler();
            setListFilters({ ...listFilters, searchString: '' });
          }}
        >
          Reset All
        </Button>
        <Button
          variant="primary"
          disabled={hasUncheckedSection()}
          onClick={listApplyFiltersHandler}
        >
          Apply Filters
        </Button>
      </Stack>
    </Stack>
  );
};

CascadingFiltersListSection.propTypes = {
  headerName: PropTypes.string,
  options: PropTypes.array,
  setCascadingFilters: PropTypes.func,
  cascadingFilters: PropTypes.array,
  setListSectionData: PropTypes.func,
  setCascadingFiltersLocal: PropTypes.func,
  cascadingFiltersLocal: PropTypes.array,
  applyFiltersHandler: PropTypes.func,
  resetAllHandler: PropTypes.func,
  hasUncheckedSection: PropTypes.func,
  cascadingFiltersConfig: PropTypes.array,
  isCascadingFilterOff: PropTypes.bool.isRequired,
};
