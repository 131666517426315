import { useState } from 'react';
import { isEmpty, isString } from 'lodash';

const isValidValue = (storedValue) =>
  storedValue !== 'undefined' && storedValue !== 'null' && isString(storedValue);

export default (key, initialValue = null) => {
  const storedValue = sessionStorage.getItem(key);
  const [value, setValue] = useState(
    isValidValue(storedValue) ? JSON.parse(storedValue) : initialValue,
  );

  const setStoredValue = (newValue) => {
    setValue(newValue);
    sessionStorage.setItem(key, JSON.stringify(newValue));
  };

  const isValueSet = isValidValue(storedValue) ?? !isEmpty(JSON.parse(storedValue));

  const clearStorage = () => sessionStorage.removeItem(key);

  return { clearStorage, value, setStoredValue, isValueSet };
};
