import theme from 'theme';

const { common, data, error, info, magenta, purple, secondary, success, teal, warning, yellow } =
  theme.palette;

export const vehicleDamageSeverityColorMap = {
  step1: error[90],
  step2: error[70],
  step3: error[60],
  step4: error[40],
  step5: error[20],
  step6: error[10],
};

export const chipColorMap = {
  currentSaleBackground: success[100],
  currentSaleText: success[10],
  creditBackground: data.orange,
  lifecycleChipBackground: info[100],
  lifecycleChipText: info[10],
  fleetChipBackground: info[100],
  fleetChipText: info[10],
  currentEvent: info[20],
  qualityConfActiveBackground: data.errorContainer,
  qualityConfActiveText: data.onErrorContainer,
  allocationUnassignedBackground: warning[100],
  allocationUnassignedText: warning[10],
  allocationInProcessBackground: purple[100],
  allocationInProcessText: purple[10],
  allocationCompletedBackground: success[100],
  allocationCompletedText: success[10],
  allocationAuthorizedBackground: info[100],
  allocationAuthorizedText: info[10],
  publishChipBackground: teal[100],
  publishChipColor: teal[30],
  etaStatusUnavailable: common.black,
  etaStatusOnSchedule: success[10],
  etaStatusAtRisk: warning[10],
  etaStatusDelayed: data.onErrorContainer,
  etaStatusEarly: purple[10],
  etaStatusDelivered: info[20],
  etaStatusUnavailableBg: common.white,
  etaStatusOnScheduleBg: success[100],
  etaStatusAtRiskBg: warning[100],
  etaStatusDelayedBg: data.errorContainer,
  etaStatusEarlyBg: purple[100],
  etaStatusDeliveredBg: info[100],
  inventoryCategoryOnSchedule: success[50],
  inventoryCategoryAtRisk: warning[50],
  inventoryCategoryEarly: purple[50],
  inventoryCategoryDelayed: error[50],
  inventoryCategoryETAUnavailable: secondary[50],
  inventoryCategoryDelivered: info[10],
  earmarkCustom: purple[10],
  earmarkRup: info[50],
  earmarkNso: info[20],
  earmarkMarketRepresentation: purple[70],
  earmarkCRReplacement: magenta[50],
  earmarkDealerRequestPreSold: teal[50],
  earmarkAutoShow: data.darkTeal,
  earmarkDamageReplacement: warning[40],
  earmarkNewModelIntro: yellow[30],
  earmarkQcReplacement: error[60],
  earmarkPlantCancelReplacement: data.darkRed,
  netSalesZeroText: data.onErrorContainer,
  netSalesZeroBg: data.errorContainer,
  netSalesText: info[10],
  netSalesBg: info[100],
};

export const customerRequestsColorMap = {
  soldOrder: success[90],
  preSold: success[60],
  reserve: success[30],
};

export const notificationColorMap = {
  error: error[50],
  info: info[50],
  warning: warning[50],
  success: success[50],
  notFound: error[50],
};

export const notificationBackgroundColorMap = {
  error: error[100],
  info: info[100],
  warning: warning[100],
  success: success[100],
  notFound: error[100],
};
