import React, { createContext, useState, useMemo, useContext, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { scrollTo } from 'utils/general';
import { delay } from 'lodash';

export const VehicleDetailsSearchContext = createContext();

const getNextScrollToResultsIndex = ({ scrollToResultsIndex, vehicleDetailsSearchResultsRef }) => {
  if (
    scrollToResultsIndex === null ||
    scrollToResultsIndex + 1 === vehicleDetailsSearchResultsRef.current.length
  ) {
    return 0;
  }
  return scrollToResultsIndex + 1;
};

const getPreviousScrollToResultsIndex = ({
  scrollToResultsIndex,
  vehicleDetailsSearchResultsRef,
}) => {
  if (scrollToResultsIndex === null || scrollToResultsIndex === 0) {
    return vehicleDetailsSearchResultsRef.current.length - 1;
  }
  return scrollToResultsIndex - 1;
};

export const VehicleDetailsSearchProvider = ({ children, value }) => {
  const [isHeaderCollapsed, setIsHeaderCollapsed] = useState(null);
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [scrollToResultsIndex, setScrollToResultsIndex] = useState(null);
  const [searchResultsCount, setSearchResultsCount] = useState(0);
  const [vehicleDetailsSearchString, setVehicleDetailsSearchString] = useState('');

  const vehicleDetailsSearchResultsRef = useRef([]);
  const countRef = useRef(0);

  useEffect(() => {
    vehicleDetailsSearchResultsRef.current = [];
    countRef.current = 0;
    setSearchResultsCount(0);
    setScrollToResultsIndex(0);
  }, [vehicleDetailsSearchString]);

  useEffect(() => {
    if (vehicleDetailsSearchResultsRef?.current?.length > 0) {
      setIsHeaderCollapsed(true);
      //Fix for iPad Safari - on first search, after scrolling to results, screen scroll back to the top.
      delay(
        () =>
          scrollTo({
            scrollToId: vehicleDetailsSearchResultsRef.current[0].fieldTarget,
          }),
        50,
      );
    }
  }, [vehicleDetailsSearchResultsRef.current]);

  const clearSearch = () => {
    countRef.current = 0;
    setIsSearchOpen(false);
    setScrollToResultsIndex(null);
    setSearchResultsCount(0);
    setVehicleDetailsSearchString('');
    vehicleDetailsSearchResultsRef.current = [];
  };

  const getActiveResultId = () => {
    return vehicleDetailsSearchResultsRef.current[scrollToResultsIndex || 0]?.fieldId;
  };
  const getCurrentPosition = () => scrollToResultsIndex + 1;

  const goToNextResult = ({ direction }) => {
    if (vehicleDetailsSearchResultsRef.current.length === 0) {
      return;
    }
    const newScrollToResultsIndex =
      direction === 'previous'
        ? getPreviousScrollToResultsIndex({ scrollToResultsIndex, vehicleDetailsSearchResultsRef })
        : getNextScrollToResultsIndex({ scrollToResultsIndex, vehicleDetailsSearchResultsRef });
    scrollTo({
      scrollToId: vehicleDetailsSearchResultsRef.current[newScrollToResultsIndex].fieldTarget,
    });
    setScrollToResultsIndex(newScrollToResultsIndex);
  };

  const contextValue = useMemo(
    () => ({
      clearSearch,
      countRef,
      getActiveResultId,
      getCurrentPosition,
      goToNextResult,
      isHeaderCollapsed,
      isSearchOpen,
      searchResultsCount,
      setIsHeaderCollapsed,
      setIsSearchOpen,
      setSearchResultsCount,
      setVehicleDetailsSearchString,
      vehicleDetailsSearchResultsRef,
      vehicleDetailsSearchString,
    }),
    [
      clearSearch,
      countRef,
      getActiveResultId,
      getCurrentPosition,
      goToNextResult,
      isHeaderCollapsed,
      isSearchOpen,
      searchResultsCount,
      setIsHeaderCollapsed,
      setIsSearchOpen,
      setSearchResultsCount,
      setVehicleDetailsSearchString,
      vehicleDetailsSearchResultsRef,
      vehicleDetailsSearchString,
    ],
  );

  return (
    <VehicleDetailsSearchContext.Provider value={value || contextValue}>
      {children}
    </VehicleDetailsSearchContext.Provider>
  );
};

VehicleDetailsSearchProvider.displayName = 'ExpandedRowsProvider';
VehicleDetailsSearchProvider.propTypes = {
  children: PropTypes.node,
  value: PropTypes.object,
};

export const useVehicleDetailsSearch = () => useContext(VehicleDetailsSearchContext);
