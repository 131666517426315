import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { withErrorBoundary } from 'react-error-boundary';
import { filter, find, forOwn, get, isEmpty, groupBy } from 'lodash';
import { useCascadingFilterContext } from 'contexts/CascadingFilters.context';
import { useProfileContext } from 'contexts/UserProfile.context';
import { useTableSelectionContext } from 'contexts/TableSelection.context';
import AlertMessage from 'components/AlertMessage/AlertMessage';
import CascadingFilters from 'components/CascadingFilters/CascadingFilters';

import { getLabelForChip } from 'components/CascadingFilters/utils';
import cascadingFiltersViewConfig from './cascadingFiltersView.config.json';
import { formatDisplayName, sortAsc } from 'components/CascadingFilters/utils';

const DISTRIBUTOR_CODES = 'PageFilter.distributorCodes';

const setDistributors = ({ distributor, distributorArr, storedFiltersGrouped }) => {
  const { distributorCode } = storedFiltersGrouped;
  distributorArr?.forEach((distr) => {
    let isChecked = true;
    if (distributorCode) {
      const fieldObj = find(distributorCode, {
        field: 'distributorCode',
        value: distr.distributorCode,
      });
      isChecked = !!fieldObj?.isChecked;
    }

    const newDistributor = {
      displayName: formatDisplayName(distr.distributorName),
      value: distr.distributorCode,
      field: 'distributorCode',
      headerName: 'Distributor',
      isChecked,
    };
    const isDistributor = !!find(distributor, newDistributor);
    if (!isDistributor) {
      distributor.push(newDistributor);
    }
  });
};
const setBrands = ({
  distributor,
  brand,
  distributorArr,
  storedFiltersGrouped,
  isCascadingFilterOff,
}) => {
  distributorArr?.forEach((distr) => {
    const isParentDistributorChecked =
      isCascadingFilterOff ||
      !!find(distributor, {
        displayName: formatDisplayName(distr.distributorName),
        value: distr.distributorCode,
        isChecked: true,
      });
    if (isParentDistributorChecked && brand.length < 3) {
      distr.brandCodes.forEach((brandCode) => {
        const { brandName = false } = storedFiltersGrouped;
        let isChecked = true;
        if (brandName) {
          const fieldObj = find(brandName, {
            field: 'brandName',
            value: brandCode.brandCode,
          });
          if (fieldObj) {
            isChecked = fieldObj.isChecked;
          }
        }
        const newBrand = {
          displayName: formatDisplayName(brandCode.brandName),
          value: brandCode.brandCode,
          field: 'brandName',
          headerName: 'Brand',
          isChecked,
        };
        const isBrand = find(brand, newBrand);
        if (!isBrand) {
          brand.push(newBrand);
        }
      });
    }
  });
};
const setRegions = ({
  distributor,
  brand,
  regionCode,
  distributorArr,
  storedFiltersGrouped,
  isCascadingFilterOff,
}) => {
  distributorArr?.forEach((distr) => {
    const isParentDistributorChecked =
      isCascadingFilterOff ||
      !!find(distributor, {
        displayName: formatDisplayName(distr.distributorName),
        value: distr.distributorCode,
        isChecked: true,
      });
    if (isParentDistributorChecked) {
      distr.brandCodes.forEach((brandCode) => {
        const isParentBrandChecked =
          isCascadingFilterOff ||
          !!find(brand, {
            displayName: formatDisplayName(brandCode.brandName),
            value: brandCode.brandCode,
            isChecked: true,
          });

        if (isParentBrandChecked) {
          brandCode.regions.forEach((brandRegion) => {
            const currentRegionCode = storedFiltersGrouped?.currentRegionCode;
            let isChecked = true;
            if (currentRegionCode) {
              const fieldObj = find(currentRegionCode, {
                field: 'currentRegionCode',
                value: brandRegion.regionCode,
              });
              if (fieldObj) {
                isChecked = fieldObj.isChecked;
              }
            }
            const newRegion = {
              displayName: formatDisplayName(
                `${brandRegion.regionCode} | ${brandRegion.regionName}`,
              ),
              value: brandRegion.regionCode,
              field: 'currentRegionCode',
              headerName: 'Region',
              isChecked,
            };
            const isRegion = find(regionCode, newRegion);
            if (!isRegion) {
              regionCode.push(newRegion);
            }
          });
        }
      });
    }
  });
  sortAsc(regionCode);
};
const setQuickFilters = ({ pageFilterData, quickFilter, storedFiltersGrouped }) => {
  const quickFilterOptions = get(pageFilterData, 'PageFilter.quickFilters');
  quickFilterOptions?.forEach((option) => {
    let isChecked = false;
    const quickFilters = storedFiltersGrouped.quickFilters;
    const displayName = formatDisplayName(option.name);

    if (quickFilters) {
      isChecked = find(quickFilters, {
        field: 'quickFilters',
        value: displayName,
      })?.isChecked;
    }
    const newQuickFilter = {
      displayName,
      value: displayName,
      field: 'quickFilters',
      headerName: 'Quick Filters',
      isChecked,
    };
    const isQuickFilter = find(quickFilter, newQuickFilter);
    if (!isQuickFilter) {
      quickFilter.push(newQuickFilter);
    }
  });
};

const getDealers = ({
  distributor,
  brand,
  regionCode,
  distributorArr,
  storedFiltersGrouped,
  isCascadingFilterOff,
  countOnly,
}) => {
  const dealerCode = [];
  let showCount = false;
  let dealerCodeCount = 0;
  distributorArr?.forEach((distr) => {
    const isParentDistributorChecked =
      isCascadingFilterOff ||
      !!find(distributor.options, {
        displayName: formatDisplayName(distr.distributorName),
        value: distr.distributorCode,
        isChecked: true,
      });
    if (isParentDistributorChecked) {
      distr.brandCodes.forEach((brandCode) => {
        const isParentBrandChecked =
          isCascadingFilterOff ||
          !!find(brand.options, {
            displayName: formatDisplayName(brandCode.brandName),
            value: brandCode.brandCode,
            isChecked: true,
          });

        if (isParentBrandChecked) {
          brandCode.regions.forEach((brandCodeRegions) => {
            const isParentRegionChecked =
              isCascadingFilterOff ||
              !!find(regionCode.options, {
                value: brandCodeRegions.regionCode,
                isChecked: true,
              });

            if (isParentRegionChecked) {
              brandCodeRegions.dealers.forEach((regionDealer) => {
                const currentDealerCode = storedFiltersGrouped?.currentDealerCode;
                let isChecked = storedFiltersGrouped?.currentDealerCode ? false : true;
                if (currentDealerCode) {
                  const fieldObj = find(currentDealerCode, {
                    field: 'currentDealerCode',
                    value: regionDealer.dealerCode,
                  });
                  if (fieldObj) {
                    isChecked = fieldObj.isChecked;
                  }
                }

                if (countOnly) {
                  if (isChecked) {
                    dealerCodeCount = dealerCodeCount + 1;
                  } else {
                    showCount = true;
                  }
                } else {
                  const newDealer = {
                    displayName: formatDisplayName(
                      `${regionDealer.dealerCode} | ${regionDealer.dealerName}`,
                    ),
                    value: regionDealer.dealerCode,
                    field: 'currentDealerCode',
                    headerName: 'Dealer',
                    isChecked,
                  };
                  const isDealer = find(dealerCode, newDealer);
                  if (!isDealer) {
                    dealerCode.push(newDealer);
                  }
                }
              });
            }
          });
        }
      });
    }
  });

  sortAsc(dealerCode);
  return countOnly ? { showCount, dealerCodeCount } : dealerCode;
};

const setFleet = ({ pageFilterData, fleetIndicator, storedFiltersGrouped }) => {
  const fleetIndicatorOptions = get(pageFilterData, 'PageFilter.fleetIndicators');
  fleetIndicatorOptions?.forEach((indicator) => {
    let isChecked = true;
    const fleetIndicators = storedFiltersGrouped.fleetIndicator;
    if (fleetIndicators) {
      isChecked = find(fleetIndicators, {
        field: 'fleetIndicator',
        value: indicator.value,
      })?.isChecked;
    }
    const newFleetIndicator = {
      displayName: formatDisplayName(indicator.name),
      value: indicator.value,
      field: 'fleetIndicator',
      headerName: 'Fleet',
      isChecked,
    };
    const isFleet = find(fleetIndicator, newFleetIndicator);
    if (!isFleet) {
      fleetIndicator.push(newFleetIndicator);
    }
  });
};
const setModelYear = ({ pageFilterData, modelYear, storedFiltersGrouped }) => {
  const modelYearsOptions = get(pageFilterData, 'PageFilter.modelYears');

  modelYearsOptions?.forEach((year) => {
    let isChecked = true;
    const modelYears = storedFiltersGrouped.modelYear;
    if (modelYears) {
      isChecked = find(modelYears, {
        field: 'modelYear',
        value: year.value,
      })?.isChecked;
    }
    const newModelYear = {
      displayName: year.name,
      value: year.value,
      field: 'modelYear',
      headerName: 'Model Year',
      isChecked,
    };
    const isFleet = find(modelYear, newModelYear);
    if (!isFleet) {
      modelYear.push(newModelYear);
    }
  });
};

const getSeries = ({
  distributor,
  brand,
  distributorArr,
  storedFiltersGrouped,
  isCascadingFilterOff,
  countOnly,
}) => {
  const series = [];
  let showCount = false;
  const uniqueSeries = new Set();

  distributorArr?.forEach((distr) => {
    const isParentDistributorChecked =
      isCascadingFilterOff ||
      !!find(distributor.options, {
        displayName: formatDisplayName(distr.distributorName),
        value: distr.distributorCode,
        isChecked: true,
      });
    if (isParentDistributorChecked) {
      distr.brandCodes.forEach((brandCode) => {
        const isParentBrandChecked =
          isCascadingFilterOff ||
          !!find(brand.options, {
            displayName: formatDisplayName(brandCode.brandName),
            value: brandCode.brandCode,
            isChecked: true,
          });

        if (isParentBrandChecked) {
          brandCode.saleSeriesNames.forEach((saleSeriesName) => {
            const salesSeriesName = storedFiltersGrouped?.salesSeriesName;
            let isChecked = storedFiltersGrouped?.salesSeriesName ? false : true;
            if (salesSeriesName) {
              const fieldObj = find(salesSeriesName, {
                field: 'salesSeriesName',
                value: saleSeriesName.saleSeriesName,
              });
              if (fieldObj) {
                isChecked = fieldObj.isChecked;
              }
            }
            if (countOnly) {
              if (isChecked) {
                uniqueSeries.add(saleSeriesName.saleSeriesName);
              } else {
                showCount = true;
              }
            } else {
              const newSeries = {
                displayName: saleSeriesName.saleSeriesName,
                value: saleSeriesName.saleSeriesName,
                field: 'salesSeriesName',
                headerName: 'Series',
                isChecked,
              };
              const isSeries = find(series, newSeries);
              if (!isSeries) {
                series.push(newSeries);
              }
            }
          });
        }
      });
    }
  });

  const seriesCount = uniqueSeries.size;
  sortAsc(series);
  return countOnly ? { showCount, seriesCount } : series;
};

const getFilterLists = ({ pageFilterData, storedFilters, isCascadingFilterOff }) => {
  const distributorArr = get(pageFilterData, DISTRIBUTOR_CODES);

  const parameters = {
    quickFilter: [],
    distributor: [],
    brand: [],
    fleetIndicator: [],
    regionCode: [],
    dealerCode: [],
    modelYear: [],
    series: [],
    distributorArr,
    storedFiltersGrouped: groupBy(storedFilters, 'field'),
    pageFilterData,
    isCascadingFilterOff,
  };

  setQuickFilters(parameters);
  setDistributors(parameters);
  setBrands(parameters);
  setFleet(parameters);
  setRegions(parameters);
  setModelYear(parameters);

  return cascadingFiltersViewConfig.map(({ field, optionsPath, headerName, type }) => ({
    field,
    options: get(parameters, optionsPath),
    headerName,
    type,
  }));
};

const addAllDistributors = ({ pageFilterData, cascadingFiltersInitial }) => {
  const isDistributorInCascadingFilters = !!find(cascadingFiltersInitial, {
    field: 'distributorCode',
  });
  const distributors = get(pageFilterData, 'PageFilter.distributorCodes');
  const cascadingFiltersInitialClone = [...cascadingFiltersInitial];

  if (!isDistributorInCascadingFilters) {
    if (distributors) {
      distributors.forEach(({ distributorCode }) => {
        cascadingFiltersInitialClone.push({
          field: 'distributorCode',
          value: distributorCode,
          isChecked: true,
        });
      });
    }
  } else {
    cascadingFiltersInitial.forEach((cascadingFilter, index) => {
      if (
        cascadingFilter.field === 'distributorCode' &&
        !find(distributors, { distributorCode: cascadingFilter.distributorCode })
      ) {
        cascadingFiltersInitialClone.splice(index, 1);
      }
    });
  }
  return cascadingFiltersInitialClone;
};

const getCascadingFiltersInitial = ({
  cascadingFiltersLocalRef,
  cascadingFiltersLocal,
  cascadingFilters,
  setIsCascadingFilterOff,
  pageFilterData,
}) => {
  let cascadingFiltersInitial;
  if (!isEmpty(cascadingFiltersLocalRef.current)) {
    cascadingFiltersInitial = cascadingFiltersLocalRef.current.cascadingFiltersLocal;
    cascadingFiltersLocalRef.current.isCascadingFilterOff && setIsCascadingFilterOff(true);
  } else {
    cascadingFiltersInitial = isEmpty(cascadingFiltersLocal)
      ? cascadingFilters
      : cascadingFiltersLocal;
  }
  return addAllDistributors({ pageFilterData, cascadingFiltersInitial });
};

const CascadingFiltersView = ({ cascadingFiltersLocalRef, setPage }) => {
  const { selectedUserProfile } = useProfileContext();
  const {
    cascadingFilters,
    data: pageFilterData,
    setCascadingFilters,
  } = useCascadingFilterContext();
  const { clearRowSelectionModel } = useTableSelectionContext();

  const [cascadingOptions, setCascadingOptions] = useState([]);
  const [cascadingFiltersLocal, setCascadingFiltersLocal] = useState([]);
  const [isCascadingFilterOff, setIsCascadingFilterOff] = useState(false);

  useEffect(() => {
    if (pageFilterData) {
      const cascadingFiltersInitial = getCascadingFiltersInitial({
        cascadingFiltersLocalRef,
        cascadingFiltersLocal,
        cascadingFilters,
        setIsCascadingFilterOff,
        pageFilterData,
      });

      setInitialCascadingFilters({ cascadingFiltersInitial });
      setCascadingFiltersLocal(cascadingFiltersInitial);
    }
  }, [pageFilterData]);

  useEffect(() => {
    if (pageFilterData) {
      cascadingFiltersLocalRef.current = { cascadingFiltersLocal, isCascadingFilterOff };
      setCascadingOptions(
        getFilterLists({
          pageFilterData,
          storedFilters: cascadingFiltersLocal,
          isCascadingFilterOff,
        }),
      );
    }
  }, [cascadingFiltersLocal, isCascadingFilterOff, selectedUserProfile]);

  const hasUncheckedSection = () =>
    cascadingOptions.some((cascadingFilter) => {
      if (cascadingFilter.field === 'quickFilters') {
        return;
      }

      if (cascadingFilter.options.length > 0) {
        const numOptions = cascadingFilter.options.length;
        let unCheckedOptions = 0;

        cascadingFilter.options.forEach((option) => {
          if (!option.isChecked) {
            unCheckedOptions++;
          }
        });

        return numOptions === unCheckedOptions;
      }
    }) ||
    dealerChipLabel?.dealerCodeCount === 0 ||
    seriesChipLabel?.seriesCount === 0;

  useEffect(() => {
    if (pageFilterData) {
      setCascadingFiltersLocal(cascadingFilters);
    }
  }, [cascadingFilters]);

  const setInitialCascadingFilters = ({ cascadingFiltersInitial }) =>
    setCascadingOptions(
      getFilterLists({
        pageFilterData,
        storedFilters: cascadingFiltersInitial,
        isCascadingFilterOff,
      }),
    );

  const getListOptions = ({ headerName, newCascadingFiltersLocal }) => {
    if (headerName === 'Dealer') {
      return getDealers({
        distributor: find(cascadingOptions, { field: 'distributorCode' }),
        brand: find(cascadingOptions, { field: 'brandName' }),
        regionCode: find(cascadingOptions, { field: 'currentRegionCode' }),
        distributorArr: get(pageFilterData, DISTRIBUTOR_CODES),
        storedFiltersGrouped: groupBy(newCascadingFiltersLocal || cascadingFiltersLocal, 'field'),
        isCascadingFilterOff,
      });
    }
    if (headerName === 'Series') {
      return getSeries({
        distributor: find(cascadingOptions, { field: 'distributorCode' }),
        brand: find(cascadingOptions, { field: 'brandName' }),
        regionCode: find(cascadingOptions, { field: 'currentRegionCode' }),
        distributorArr: get(pageFilterData, DISTRIBUTOR_CODES),
        storedFiltersGrouped: groupBy(newCascadingFiltersLocal || cascadingFiltersLocal, 'field'),
        isCascadingFilterOff,
      });
    }
    return [];
  };

  const applyFiltersHandler = ({ newCascadingFiltersLocal }) => {
    setPage(0);
    clearRowSelectionModel();
    let filters = [];
    const groupedCascadingFiltersLocal = groupBy(
      newCascadingFiltersLocal || cascadingFiltersLocal,
      'field',
    );

    forOwn(groupedCascadingFiltersLocal, (value, field) => {
      let options;
      if (field === 'currentDealerCode') {
        options = getListOptions({ headerName: 'Dealer', newCascadingFiltersLocal });
      } else if (field === 'salesSeriesName') {
        options = getListOptions({ headerName: 'Series', newCascadingFiltersLocal });
      } else {
        options = find(cascadingOptions, { field }).options;
      }

      const valueIsChecked = filter(value, { isChecked: true });

      if (options.length !== valueIsChecked.length) {
        const filterOptions = options.map(({ field, value, isChecked }) => ({
          field,
          value,
          isChecked,
        }));
        filters = [...filters, ...filterOptions];
      }
    });

    setCascadingFilters(filters);
  };

  const dealerChipLabel = useMemo(
    () =>
      !isEmpty(cascadingOptions) &&
      getDealers({
        distributor: find(cascadingOptions, { field: 'distributorCode' }),
        brand: find(cascadingOptions, { field: 'brandName' }),
        regionCode: find(cascadingOptions, { field: 'currentRegionCode' }),
        distributorArr: get(pageFilterData, DISTRIBUTOR_CODES),
        storedFiltersGrouped: groupBy(cascadingFiltersLocal, 'field'),
        isCascadingFilterOff,
        countOnly: true,
      }),
    [cascadingOptions, cascadingFiltersLocal, pageFilterData],
  );

  const seriesChipLabel = useMemo(
    () =>
      !isEmpty(cascadingOptions) &&
      getSeries({
        distributor: find(cascadingOptions, { field: 'distributorCode' }),
        brand: find(cascadingOptions, { field: 'brandName' }),
        distributorArr: get(pageFilterData, DISTRIBUTOR_CODES),
        storedFiltersGrouped: groupBy(cascadingFiltersLocal, 'field'),
        isCascadingFilterOff,
        countOnly: true,
      }),
    [cascadingOptions, cascadingFiltersLocal, pageFilterData],
  );

  const getChipLabel = ({ headerName, options }) => {
    if (headerName === 'Dealer') {
      return dealerChipLabel.showCount ? dealerChipLabel.dealerCodeCount : 'ALL';
    }
    if (headerName === 'Series') {
      return seriesChipLabel.showCount ? seriesChipLabel.seriesCount : 'ALL';
    }
    return getLabelForChip({ headerName, options });
  };

  return (
    <CascadingFilters
      applyFiltersHandler={applyFiltersHandler}
      cascadingFiltersConfig={cascadingFiltersViewConfig}
      cascadingFiltersLocal={cascadingFiltersLocal}
      cascadingFiltersLocalRef={cascadingFiltersLocalRef}
      cascadingOptions={cascadingOptions}
      getCascadingFiltersInitial={getCascadingFiltersInitial}
      getChipLabel={getChipLabel}
      getFilterLists={getFilterLists}
      getListOptions={getListOptions}
      hasUncheckedSection={hasUncheckedSection}
      isCascadingFilterOff={isCascadingFilterOff}
      setCascadingFiltersLocal={setCascadingFiltersLocal}
      setCascadingOptions={setCascadingOptions}
      setIsCascadingFilterOff={setIsCascadingFilterOff}
    />
  );
};

CascadingFiltersView.propTypes = {
  cascadingFiltersLocalRef: PropTypes.object,
  setPage: PropTypes.func,
};

export default withErrorBoundary(CascadingFiltersView, {
  fallback: <AlertMessage />,
});
