import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';

export const ExternalAppsContext = createContext();

const ExternalAppsProvider = ({ children }) => {
  const [open, setOpen] = useState(false);

  const contextValue = {
    open,
    setOpen,
  };

  return (
    <ExternalAppsContext.Provider value={contextValue}>{children}</ExternalAppsContext.Provider>
  );
};

ExternalAppsProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ExternalAppsProvider;
