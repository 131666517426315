import React, { createContext, useState, useContext } from 'react';
import PropTypes from 'prop-types';

export const TableRowSelection = createContext();

export const TableRowSelectionProvider = ({ children, selectedItems }) => {
  const [rowSelectionModel, setRowSelectionModel] = useState(
    selectedItems?.length ? new Map(selectedItems) : new Map(),
  );

  const clearRowSelectionModel = () => setRowSelectionModel(new Map());

  return (
    <TableRowSelection.Provider
      value={{ rowSelectionModel, setRowSelectionModel, clearRowSelectionModel }}
    >
      {children}
    </TableRowSelection.Provider>
  );
};

TableRowSelectionProvider.propTypes = {
  children: PropTypes.node,
};

export default TableRowSelectionProvider;

TableRowSelectionProvider.propTypes = {
  selectedItems: PropTypes.any,
};

export const useTableSelectionContext = () => useContext(TableRowSelection);
