import { filter } from 'lodash';
import { truncateText } from '../../utils/general.js';

const mapSectionChipLabel = ({ displayName }) => {
  switch (displayName.toUpperCase()) {
    case 'UNITED STATES':
      return 'USA';
    default:
      return displayName;
  }
};

export const getLabelForChip = ({ headerName, options }) => {
  const numOfSectionItems = options?.length;
  const sectionCheckedItems = filter(options, { isChecked: true });
  const numOfSectionCheckedItems = sectionCheckedItems?.length;

  const isAllIntermediate =
    numOfSectionItems > numOfSectionCheckedItems && numOfSectionCheckedItems > 0;
  const isAllSectionItemsChecked =
    !isAllIntermediate && numOfSectionItems === numOfSectionCheckedItems;

  if (isAllSectionItemsChecked) {
    return 'ALL';
  }
  if (numOfSectionCheckedItems === 1) {
    const MAX_CHAR = 22;
    const displayName = sectionCheckedItems[0]?.displayName;
    const sectionChipLabel = displayName ? mapSectionChipLabel({ displayName }) : '';
    const maxLength = MAX_CHAR - headerName.length;
    return truncateText({ text: sectionChipLabel, maxLength }).toUpperCase();
  }
  return numOfSectionCheckedItems;
};

export const sortAsc = (arr) =>
  arr.sort((a, b) => {
    const optionA = a.displayName.toLowerCase();
    const optionB = b.displayName.toLowerCase();

    if (optionA < optionB) {
      return -1;
    }
    if (optionA > optionB) {
      return 1;
    }
    return 0;
  });

export const formatDisplayName = (inputString) =>
  inputString
    ? inputString.toLowerCase().replace(/\b(?:tms|hq|[a-zA-Z])\w*\b/g, (match) =>
        // Capitalize 'tms' and 'hq', and capitalize other words
        match === 'tms' || match === 'hq'
          ? match.toUpperCase()
          : match.charAt(0).toUpperCase() + match.slice(1),
      )
    : '';
